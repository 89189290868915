@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans&display=swap');


.dropdown:hover .dropdown-menu {
  display: block;
  position: absolute;
  
}
.bg-inherit {
  background-color:rgb(89, 180, 154)
  ;
}